.react-bootstrap-table {
	table.releases-table {
		border-collapse: separate;
		border-spacing: 0;
		margin-bottom: 0;

		&.table-bordered > :not(caption) > * > * {
			border-width: 0 1px 1px 0;
		}

		& > thead > tr:nth-child(1) > th:not(:first-child) {
			vertical-align: middle;
			position: -webkit-sticky; /* for Safari */
			position: sticky;
			top: 0;
			background-color: $white;
			border-top-width: 1px;
			z-index: 1;
		}

		& > thead > tr:nth-child(1) > th:nth-child(1),
		& > tbody > tr > td[scope='row'] {
			position: -webkit-sticky; /* for Safari */
			position: sticky;
			left: 0;
			background-color: $white;
			border-left-width: 1px;
			z-index: 1;
		}

		& > thead > tr:nth-child(1) > th:first-child {
			z-index: 2;
			top: 0;
			left: 0;
			border-top-width: 1px;
		}

		tbody > tr:first-child > td:first-child {
			vertical-align: bottom;
			text-align: center;
		}

		tbody > tr > td .alert {
			max-width: 25rem;
		}

		thead > tr > th,
		tbody > tr > td {
			&:not(:first-child) {
				min-width: 25rem;
			}
		}
	}

	table.modules-table {
		tbody > tr > td {
			&:first-child {
				font-weight: $font-weight-bold;
				white-space: nowrap;
			}
		}
	}
}


.log-entry-table {
	.search-label {
		margin-bottom: 0;
		flex-grow: 1;
		margin-right: 1rem;
	}
	.pagination {
		justify-content: center;
	}
}