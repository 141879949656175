@import "~@uw-it-sis/lib-react/lib/scss/index";

// To import an app specific bit of SCSS you go ...
// @import "foo/component";
//  .... where foo is a directory in src/main/scss and component.scss is a file in the directory.

@import "table";

.app-wrapper .container-xxl {
	max-width: none;
}

.badge.bg-inactive {
    background-color: #8c959d !important;
}

.btn-group {
	@each $breakpoint in map-keys($grid-breakpoints) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		&.btn-group-vertical#{$infix} {
			@include media-breakpoint-down($breakpoint) {
				flex-direction: column;

				// Reset rounded corners
				> .btn {
					&:not(:first-child) {
						margin-top: -$btn-border-width;
						margin-left: 0;
					}
					&:not(:first-child):not(:last-child) {
						@include border-radius(0 !important);
					}
					&:first-child {
						@include border-top-radius($btn-border-radius !important);
					}
					&:last-child {
						@include border-bottom-radius($btn-border-radius !important);
					}
				}
			}
		}
	}
}

.alert > .list-unstyled {
	padding: 0;

	&:before {
		display: none;
	}
}

ul.tabular-data {
	@include list-unstyled();
	margin: 0;

	& > li {
		display: table-row;
		width: auto;

		& > div {
			display: table-cell;
			vertical-align: top;

			&.title {
				white-space: nowrap;
				padding-right: 0.75rem;
				font-weight: $font-weight-bold;
			}
		}
	}
}

.gearRight {
    animation: rotate 4s linear infinite;
    height: 1.25em;
    width: 1.25em;
}

.gearLeft {
    animation: rotate 4s linear infinite reverse;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.errorSymbol {
    height: 1.25em;
    width: 1.25em;
}
